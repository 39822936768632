import React, { useState } from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import Text from "../Text"
import Gadget from "../Gadget"
import Dots from "../Dots"
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap"

import * as s from "./style.module.css"
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Contact({ lang }) {
  const t = useLabels(labels, lang)
  const [message, setMessage] = useState("")

  const onSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const contact = {}
    for (let entry of formData.entries()) {
      contact[entry[0]] = entry[1]
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...contact }),
    })
      .then(() => setMessage("Message was send!"))
      .catch(error => setMessage(error))

    return false
  }

  return (
    <div id="form" className={s.root}>
      <div className={s.bgWrapper}>
        <div />
        <div />
      </div>
      <Container className="h-100">
        <Row>
          <Col md="6" className="position-relative">
            <div className={s.contentWrapper}>
              <Text title={t.title} text={t.description} />
              <div className="pt-2 pb-2 d-flex align-items-center">
                <img
                  alt="ikona telefonu"
                  className="mr-4"
                  src={require("./images/ico-telefon.svg").default}
                />
                <div className={s.details}>{t.phone}</div>
              </div>
              <div className="d-flex align-items-center">
                <img
                  alt="ikona maila"
                  className="mr-4"
                  src={require("./images/ico-mail.svg").default}
                />
                <div className={s.details}>{t.email}</div>
              </div>
              <img
                className={s.beTheChange}
                src={require("./images/beTheCange.svg").default}
              />
            </div>
          </Col>
          <Col md="6">
            <div className={s.formWrapper}>
              <form
                netlify
                name="contact"
                method="POST"
                data-netlify="true"
                onSubmit={onSubmit}
              >
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="name">{t.form.name}</Label>
                      <Input required type="text" name="name" id="name" />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="email">{t.form.email}</Label>
                      <Input required type="email" name="email" id="email" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="topic">{t.form.topic}</Label>
                      <Input required type="text" name="topic" id="topic" />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="number">{t.form.phone}</Label>
                      <Input type="text" name="number" id="number" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="text">{t.form.text}</Label>
                      <Input
                        required
                        className={s.textarea}
                        type="textarea"
                        name="text"
                        id="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup check>
                  <Input
                    required
                    className={s.agreementCheckbox}
                    type="checkbox"
                    name="check"
                    id="agreement"
                  />
                  <Label
                    className={s.agreement}
                    for="agreement"
                    check
                    dangerouslySetInnerHTML={{ __html: t.form.agreement }}
                  />
                </FormGroup>
                {message && (
                  <Alert className="mt-4" color="secondary">
                    {message}
                  </Alert>
                )}
                <Button
                  className="mt-4"
                  color="primary"
                  dangerouslySetInnerHTML={{ __html: t.form.send }}
                />
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact
