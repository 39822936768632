export default {
  en: {
    title: "",
  },
  pl: {
    title: "Dołącz do nas!",
    description: `
      <p><strong>Czy podoba Ci się Idea Hack4change?</strong></p>
      <p>Chcesz wesprzeć nas merytorycznie podczas wydarzenia i pomóc naszym uczestnikom podczas sesji mentorskich? Może widzisz dla siebie przestrzeń podczas warsztatów wprowadzających w tematykę hackathonu?</p>
      <p><strong>Jeżeli tak - koniecznie zgłoś się do nas!</strong></p>
    `,
    email: "kontakt@hack4change.tech",
    phone: "+48 732 012 597",
    button: "Weź udział! &rarr;",
    form: {
      title: "Skontaktuj się z nami",
      name: "Imię i nazwisko:",
      email: "Adres e-mail:",
      topic: "Temat wiadomości:",
      phone: "Numer telefonu:",
      text: "Treści wiadomości",
      agreement:
        "Administratorem danych osobowych jest Fundacja CODE:ME z siedzibą w Gdańsku Aleja Wojska Polskiego 41, 80-268 NIP 5842749072. Dane wpisane w formularzu kontaktowym będą przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z <a href='/Polityka_prywatności_hack4change_2023.pdf' target='_blank'>polityką prywatności</a>*",
      send: "Wyślij wiadomość &rarr;",
    },
  },
}
